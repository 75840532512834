<template>
  <div class="tw-flex tw-w-full tw-h-auto" style="background: white;">
    <v-col sm="12" lg="5" class="tw-hidden left-side lg:tw-flex">

      <OnboardingLeft />
    </v-col>
    <v-col sm="12" lg="7" class="right-side" style="position: relative">
      <div style="display: flex; top: 0px; position: absolute; left: 0px; right: 0px; bottom: 0px; background-color: rgba(247, 247, 247, 0.6); z-index: 9999; justify-content: center; align-items: center;" v-if="getCountriesLoading">
        <div>
            <div>
                <v-progress-circular
                  :size="70"
                  :width="7"
                  color="#1E5770"
                  indeterminate
                ></v-progress-circular>
            </div>
        </div>
      </div>
      <v-col sm="12" class="right-side-body">
        <img src="@/assets/bluelogo.png" alt="logo" style="height: 34px; width: 136px;" class="mb-3 mt-6 img-logo">

        <p class="welcome tw-mt-0">Company Address</p>
        <p class="admin tw-mt-2">
          We’d love to know how to locate you.
        </p>

        <v-row class="form">
          <v-col lg="6" sm="12" cols="12" class="mb-md-5">
            <ValidationProvider name="Country" rules="required" v-slot="{ classes, errors }">
              <label class="label">Country</label>
              <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                <v-combobox
                  :items="countries"
                  placeholder="Choose Country"
                  append-icon="mdi-chevron-down"
                  item-text="name" 
                  item-value="name"
                  v-model="address.country"
                  @change="getState()"
                  solo
                  hide-details
                  color="#1E5770"
                ></v-combobox>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
           

          </v-col>
          
          <v-col lg="6" sm="12" cols="12" class="mb-md-5">
            <ValidationProvider name="State" rules="required" v-slot="{ classes, errors }">
              <label  class="label">State</label>
              <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                <v-combobox
                  :items="states"
                  placeholder="Choose State"
                  append-icon="mdi-chevron-down"
                  item-text="name" 
                  item-value="name"
                  v-model="address.state"
                  @change="getCity()"
                  solo
                  hide-details
                  color="#1E5770"
                ></v-combobox>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </v-col>
          

          <v-col lg="6" sm="12" cols="12" class="mb-md-5">
            <ValidationProvider name="City/Town" rules="required" v-slot="{ classes, errors }">
              <label class="label">City/Town</label>
              <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                
                <v-combobox
                  :items="cities"
                  placeholder="Choose State"
                  append-icon="mdi-chevron-down"
                  v-model="address.city"
                  @change="getCity()"
                  solo
                  hide-details
                  color="#1E5770"
                ></v-combobox>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
           

          </v-col>
          
          <v-col lg="6" sm="12" cols="12" class="mb-md-5">
            <ValidationProvider name="Street Name" rules="required" v-slot="{ classes, errors }">
              <label  class="label">Street Name</label>
              <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                <v-text-field  placeholder="Street Name" solo hide-details type="text" v-model="address.streetName"  required></v-text-field>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>

          </v-col>

          <v-col lg="6" sm="12" cols="12" class="mb-md-5">
            <ValidationProvider name="House Number" rules="required" v-slot="{ classes, errors }">
              <label class="label">House Number</label>
              <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                <v-text-field placeholder="House Number" solo hide-details type="Number" v-model="address.houseNumber" required></v-text-field>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>

          </v-col>
        </v-row>

        <div class="btn-div tw-flex lg:tw-justify-end tw-justify-center">
          <router-link :to="{name: 'CompanyInfo'}" class="btn-div-text">
            <img src="../assets/arrow-back.svg" alt="" class="btn-div-img"> 
            <span> 
              Previous  
            </span>
          </router-link>
          <v-btn text color="white" :disabled="!formIsValid"  :loading="loading" class="submit"  @click.prevent="saveData">
           <span>Next</span>
            <img src="../assets/arrow_forward.svg" alt="" class="btn-div-img ml-3">  
          </v-btn>

        </div>
      </v-col>
    </v-col>

  </div>
</template>

<script>
  import OnboardingLeft from '@/components/reuseables/OnboardingLeft'
  import { getAllCountries, getAllStatesByCountry, getAllCitiesInAState } from '@/services/api/APIService'
  import Lga from "../assets/lga";
  export default {
    name: "OnboardingCompanyAddress",
    components: {
      OnboardingLeft
    },
    data() {
      return  {
        address:{
          houseNumber : "",
          streetName : "",
          city: "",
          state: "",
          country: ""
        },
        loading: false,
        getCountriesLoading: false,
        getStatesLoading: false,
        getCitiesLoading: false,
        countries: [],
        states: [],
        cities: []
      }
    },
    computed: {
      storedData() {
        return JSON.parse(sessionStorage.getItem('developerData'))
      },
      formIsValid() {
        return this.address.houseNumber !== '' && this.address.streetName !== '' && this.address.city !== '' && this.address.state !== '' && this.address.country !== ''
      },
    },
    methods: {
      async getCountries() {
        try {
          this.getCountriesLoading = true
          const response = await getAllCountries()
          this.countries = response.data.data
          this.getCountriesLoading = false
        } catch(err) {
          console.log(err.response)
          this.getCountries = false
        }
      },
      async getState() {
        try {

          this.getStatesLoading = true
          let data = {
            country: this.address.country
          }
          if (this.address.country.name){
            data.country = this.address.country.name
          }
          const response = await getAllStatesByCountry(data)
          this.states = response.data.data.states
            if (this.address.country.name.toLowerCase() === 'nigeria' ){
                this.states.push({name: 'Rivers State'})
            }
          this.states.forEach(state => state.name === 'Lagos State' ? state.name = 'Lagos' : state.name)
          this.getStatesLoading = false
        } catch(err) {
          console.log(err)
        }
      },
      async getCity() {
        if (this.address.country.name === "Nigeria") {
          this.cities = Lga[this.address.state.name]
        }
        else {
          try {
            this.getCitiesLoading = true;
            let data = {
              country: this.address.country,
              state: this.address.state
            };
            if (this.address.country.name){
              data.country = this.address.country.name;
            }
            if (this.address.state.name){
              data.state = this.address.state.name
            }
            const response = await getAllCitiesInAState(data);

            this.cities = response.data.data;
            this.getCitiesLoading = false;
          } catch (err) {
            console.log(err.response);
            this.cities = [];
            this.getCitiesLoading = false;
          }
        }
      },
     async saveData() {
        this.loading = true
        let userData = JSON.parse(sessionStorage.getItem("userData"))
        if(this.address.country.name){
          this.address.country = this.address.country.name
        }
        if(this.address.state.name){
          this.address.state = this.address.state.name
        }
        console.log(this.address, "the address")
        let data = {
          ...this.storedData,
          userId: userData.id,
            address: this.address,
        }
        sessionStorage.setItem("developerData", JSON.stringify(data))
        await this.$router.push({name: 'AccountDetails'})

      }
    },
    mounted() {
      this.getCountries()
    }
  }
</script>

<style lang="scss" scoped>
button:disabled,
button[disabled]{
  opacity: 0.7;
}
.left-side{
  width: 100%;
  min-height: 100vh;
  // height: 100%;
  background-image: url('../assets/onboarding-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 0!important;
  color: #ffffff;

  @media screen and (max-width: 1024px){
    display: none;
  }
}
.right-side-body{
  width: 100%;
  padding:7rem;

  @media screen and (min-width: 1440px) {
    padding-top: 4.25rem;
  }

  @media screen and (max-width: 1440px ){
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  @media screen and (max-width: 1366px ) and (min-width: 1025px){
    padding-top: 3.5rem;
    padding-bottom: 2rem;
  }
  @media screen and (max-width: 1024px ){
    padding: 1.5rem;
  }
}
.right-side-body > .img-logo{
  @media screen and (min-width: 1024px){
    display: none;
  }
}


.right-side-body .welcome {
  font-family: "Inter", serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #1E5770;

  @media screen and (max-width: 1024px){
    margin-top: 50px;
  }
}

.right-side-body .admin {
  width: 255px;
  height: 24px;
  font-family: "Inter",sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #828282;
  margin-top: 17px;
}


.label {
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.btn-div{
  margin-top: 52px;
  @media screen and (max-width: 1023px){
    margin-top: 25px;
  }
  
}

.btn-div .btn-div-text:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;
  border-radius: 10px;
  width: 161px;
  background: white;
  color: #1E5770;
  outline: 1px solid #1E5770;
  .btn-div-img {
    margin-right: 5px;
  }
}

.btn-div .btn-div-text:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 35px;
  //padding: 10px 30px;
  width: 161px;
  border-radius: 10px;
  background: #1E5770;
  color: white;
  .btn-div-img {
    margin-left: 5px;
  }
}

.form {
  margin-top: 39px;

  @media screen and (max-width: 1024px) {
    margin-top: 60px;
  }
}
.submit{
  background-color: #1E5770;
  width: 161px;
  border-radius: 10px;
  color: #FFFFFF !important;
  padding-top: 1.5rem!important;
  padding-bottom: 1.5rem!important;
  margin-left: 35px;
  text-transform: none;
}
</style>